import axios from "axios";
import URLHelper from "../helper/URLHelper";
import DateFormatter from "../helper/DateFormatter";


let InvoiceData = {
    getInvoices: async function () {
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/invoice`);
        return (await response.data);
    },
    getInvoice: async function (invoiceId) {
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/invoice/${invoiceId}`);
        return (await response.data);
    },
    getConfig: async function(id){
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/invoiceconfig/${id}`);
        return (await response.data)[0];
    },
    addOrUpdateConfig: async function(data, callback){
        if (data.Id > 0) {
            let configId = data.Id;
            delete data.Id;

            axios.put(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/invoiceconfig',
                {configId: configId, configModel: data}
            )
                .then((res) => {
                    callback(res.data)
                });
        }
        else {
            delete data.Id;
            axios.post(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/invoiceconfig', {data})
                .then((res) => {
                    callback(res.data)
                });
        }
    },
    generateInvoiceFromCustomer: async function(customerData, callback){
        let invoiceObjectQueryString = URLHelper.getQueryString({
            Customer: customerData.Customer,
            StartDate: DateFormatter.getSQLDateFormatted(customerData.DateRange[0]),
            EndDate: DateFormatter.getSQLDateFormatted(customerData.DateRange[1])
        })
        axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/invoicegenerate?'+invoiceObjectQueryString)
            .then((res) => {
                callback(res.data);
            });
    },
    deleteInvoice: async function(rowData, callback){
        axios.delete(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/invoice/' + rowData.Id)
            .then((res) => {
                callback(res.data);
            });
    }
}

export default InvoiceData;
import axios from 'axios';

let CustomerData = {
    getCustomers: async function () {
        let response = await axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/customer');
        return await response.data;
    },
    getCustomer: async function (customerId) {
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/customer/${customerId}`);

        return (await response.data)[0];
    },

    addOrUpdateCustomer: async function (customerModel, callback) {
        if (customerModel.Id > 0) {
            let customerId = customerModel.Id;
            delete customerModel.Id;

            axios.put(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/customer',
                {customerId: customerId, customerModel: customerModel}
            )
                .then((res) => {
                    callback(res.data)
                });
        }
        else {
            delete customerModel.Id;
            axios.post(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/customer', {customerModel})
                .then((res) => {
                    callback(res.data)
                });
        }
    },
    deleteCustomer: async function (id, callback){
        axios.delete(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/customer/' + id)
            .then((res) => {
                callback(res.data)
            });
    }
}

export default CustomerData;
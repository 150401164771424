import React, {useEffect} from 'react';
import {Modal, Form, Schema, Button, Table, Grid, Row, Col, InputNumber, Toggle} from 'rsuite';
import CustomerData from "../data/CustomerData";
import Swal from "sweetalert2";

// Icons
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

function Customers() {
    const initialFormValue = {
        Id: 0,
        Name: '',
        Address: '',
        Zip: '',
        City: '',
        UID: '',
        ReverseCharge: true
    }

    const model = Schema.Model({
        Name: Schema.Types.StringType().isRequired("Das ist ein Pflichtfeld"),
        Address: Schema.Types.StringType().isRequired("Das ist ein Pflichtfeld"),
        Zip: Schema.Types.StringType().isRequired("Das ist ein Pflichtfeld"),
        City: Schema.Types.StringType().isRequired("Das ist ein Pflichtfeld"),
    });

    const formRef = React.useRef();

    const Checker = React.forwardRef((props, ref) => <Toggle {...props} ref={ref} checked={props.hasOwnProperty("value") ? !(!props.value) : false} />);

    let [formError, setFormError] = React.useState({});
    let [formValue, setFormValue] = React.useState(initialFormValue);
    let [open, setOpen] = React.useState(false);
    let [modalTitle, setModalTitle] = React.useState("");
    let [tableData, setTableData] = React.useState([]);
    let [tableBusy, setTableBusy] = React.useState(true);


    const setCustomersData = async () => {
        let response = await CustomerData.getCustomers();
        setTableData(response);
        setTableBusy(false);
    }

    useEffect(() => {
        setCustomersData();
    }, []);


    function handleClose() {
        setOpen(false);
        console.log(formValue);
    }

    function handleConfirm() {
        if (typeof (formRef.current) !== "undefined") {
            if (formRef.current.check()) {
                CustomerData.addOrUpdateCustomer(formValue, (res) => {
                        if (res.success) {
                            Swal.fire(
                                "Erfolgreich gespeichert",
                                `<b>${formValue.Name}</b> erfolgreich gespeichert`,
                                "success"
                            ).then(() => {
                                setCustomersData();
                                handleClose()
                            });
                        } else {
                            Swal.fire(
                                "Fehler beim Speichern",
                                ``,
                                "error"
                            )
                        }
                    }
                );
            }
        }
    }

    function handleCancel() {
        handleClose();
    }

    function handleNew() {
        setModalTitle("Neuer Kunde");
        setFormValue(initialFormValue);
        setOpen(true);
    }

    function handleChange(formValue) {
        setFormValue(formValue);
    }

    function handleEdit(id, event) {
        setModalTitle("Kunde bearbeiten");
        CustomerData.getCustomer(id).then((response) => {
            setFormValue(response)
        });
        setOpen(true);
    }

    function handleDelete(id, event) {
        CustomerData.getCustomer(id).then((response) => {
            Swal.fire({
                title: "Wirklich löschen?",
                text: `Soll ${response.Name} wirklich gelöscht werden?`,
                icon: "warning",
                confirmButtonText: 'Löschen',
                cancelButtonText: "Abbrechen",
                showCancelButton: true
            }).then((res) => {
                if (res.isConfirmed)
                    CustomerData.deleteCustomer(id,
                        (response) => {
                            if (response.success)
                                setCustomersData();
                        }
                    );
            })
        });
    }

    return (
        <>
            <h1>Kunden</h1>
            <Modal open={open} onClose={handleClose} size="lg">
                <Modal.Header>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight: 300}}>
                    <Form ref={formRef}
                          model={model}
                          fluid
                          onChange={formValue => handleChange(formValue)}
                          onCheck={setFormError}
                          formError={formError}
                          formValue={formValue}
                          checkTrigger={"none"}>
                        <Grid fluid>
                            <Row className={"mb-2"}>
                                <Col md={24}>
                                    <Form.Group controlId="Name">
                                        <Form.ControlLabel>Name*</Form.ControlLabel>
                                        <Form.Control name="Name"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={"mb-2"}>
                                <Col md={24}>
                                    <Form.Group controlId="Address">
                                        <Form.ControlLabel>Adresse*</Form.ControlLabel>
                                        <Form.Control name="Address"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={"mb-2"}>
                                <Col md={8}>
                                    <Form.Group controlId="Zip">
                                        <Form.ControlLabel>PLZ*</Form.ControlLabel>
                                        <Form.Control name="Zip"/>
                                    </Form.Group>
                                </Col>
                                <Col md={16}>
                                    <Form.Group controlId="City">
                                        <Form.ControlLabel>Stadt*</Form.ControlLabel>
                                        <Form.Control name="City"/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={"mb-2"}>
                                <Col md={12}>
                                    <Form.Group controlId="UID">
                                        <Form.ControlLabel>UID</Form.ControlLabel>
                                        <Form.Control name="UID"/>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group controlId="TimeToPay">
                                        <Form.ControlLabel>Zahlungsziel</Form.ControlLabel>
                                        <Form.Control name="TimeToPay" accepter={InputNumber} prefix={"Tage"}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className={"mb-2"}>
                                <Col md={12}>
                                    <Form.Group controlId={"ReverseCharge"}>
                                        <Form.ControlLabel>Reverse-Charge</Form.ControlLabel>
                                        <Form.Control name={"ReverseCharge"} accepter={Checker} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Grid>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleConfirm} appearance="primary">
                        Speichern
                    </Button>
                    <Button onClick={handleCancel} appearance="subtle">
                        Abbrechen
                    </Button>
                </Modal.Footer>
            </Modal>
            <Button onClick={handleNew} appearance={"primary"}>Neuer Kunde</Button>
            <Table data={tableData} loading={tableBusy}>
                <Table.Column fixed>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.Cell dataKey="Id"/>
                </Table.Column>
                <Table.Column width={200}>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.Cell dataKey="Name"/>
                </Table.Column>
                <Table.Column width={200}>
                    <Table.HeaderCell>UID</Table.HeaderCell>
                    <Table.Cell dataKey="UID"/>
                </Table.Column>
                <Table.Column width={200}>
                    <Table.HeaderCell>Zahlungsziel</Table.HeaderCell>
                    <Table.Cell>
                        {rowData => {
                            return (<>{rowData.TimeToPay} Tage</>);
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column width={200}>
                    <Table.HeaderCell>Reverse-Charge</Table.HeaderCell>
                    <Table.Cell>
                        {rowData => {
                            return (
                                <>
                                    {rowData.ReverseCharge ? "✔" : ""}
                                </>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column fixed="right" verticalAlign={'middle'}>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.Cell>
                        {rowData => {
                            return (
                                <>
                                    <EditIcon className={'Table-Icon'} onClick={(e => handleEdit(rowData.Id, e))}/>
                                    <TrashIcon className={'Table-Icon'} onClick={(e => handleDelete(rowData.Id, e))}/>
                                </>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </>
    )
        ;
}

export default Customers;
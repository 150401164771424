import {Table} from "rsuite";
import CheckRoundIcon from "@rsuite/icons/CheckRound";
import React, {useEffect, useState} from "react";
import projectData from "../data/ProjectData";
import DateFormatter from "../helper/DateFormatter";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import Swal from "sweetalert2";
import TimeTrackingData from "../data/TimeTrackingData";
import ProjectData from "../data/ProjectData";


function ExpenseOverview(props) {
    let [expenseData, setExpenseData] = useState([]);
    let [tableBusy, setTableBusy] = useState(false);
    let [autoHeight, setAutoHeight] = useState(props["autoHeight"] ? props["autoHeight"] : false);
    let [height, setHeight] = useState(props["height"] ? props["height"] : 1000);
    let [projectId, setProjectId] = useState(props["projectId"] ? props["projectId"] : null);

    if(props.hasOwnProperty(("eventEmitter")))
        props.eventEmitter.on('update', () => loadExpenseData(projectId));

    async function loadExpenseData(projectId) {
        if(!projectId)
            return;

        let tempExpenseData = await projectData.getProjectExpenses(projectId);
        setExpenseData(tempExpenseData);
    }

    useEffect(() => {
        console.log(`ProjectId changed "ExpensesOverview:" ${projectId}`)
        setProjectId(props.projectId)
        loadExpenseData(props.projectId);
    }, [props.projectId]);

    function handleEdit(expenseId, e) {

    }

    function handleDelete(expenseId, e) {
            Swal.fire({
                title: "Wirklich löschen?",
                text: "Soll die Projektausgabe wirklich gelöscht werden?",
                icon: "warning",
                confirmButtonText: 'Löschen',
                cancelButtonText: "Abbrechen",
                showCancelButton: true
            }).then((res) => {
                if (res.isConfirmed)
                    ProjectData.deleteProjectExpense(expenseId,
                        (response) => {
                            if (response.success)
                                loadExpenseData(props.projectId);
                        }
                    );
            })
    }

    return (
        <Table data={expenseData}
               cellBordered={true}
               loading={tableBusy}
               autoHeight={autoHeight}
               height={height}>
            <Table.Column>
                <Table.HeaderCell>ExpenseId</Table.HeaderCell>
                <Table.Cell dataKey={"ExpenseId"}/>
            </Table.Column>
            <Table.Column width={150}>
                <Table.HeaderCell>Datum</Table.HeaderCell>
                <Table.Cell>
                    {
                        (rowData) => rowData.InvoiceDate ? DateFormatter.getDateFormatted(rowData.InvoiceDate) : ''
                    }
                </Table.Cell>
            </Table.Column>
            <Table.Column width={150}>
                <Table.HeaderCell>Bezeichnung</Table.HeaderCell>
                <Table.Cell dataKey={"Caption"}/>
            </Table.Column>
            <Table.Column width={150}>
                <Table.HeaderCell>Menge</Table.HeaderCell>
                <Table.Cell dataKey={"Amount"}/>
            </Table.Column>
            <Table.Column width={150}>
                <Table.HeaderCell>Preis</Table.HeaderCell>
                <Table.Cell dataKey={"SinglePrice"}/>
            </Table.Column>
            <Table.Column width={150}>
                <Table.HeaderCell>Preis Gesamt</Table.HeaderCell>
                <Table.Cell>
                    {
                        rowData => {
                            return "€ " + Math.round((rowData.Amount * rowData.SinglePrice) * 100) / 100;
                        }
                    }
                </Table.Cell>
            </Table.Column>
            <Table.Column width={150}>
                <Table.HeaderCell>Projekt</Table.HeaderCell>
                <Table.Cell dataKey={"ProjectCaption"}/>
            </Table.Column>
            <Table.Column width={150}>
                <Table.HeaderCell>Kunde</Table.HeaderCell>
                <Table.Cell dataKey={"Customer"}/>
            </Table.Column>
            <Table.Column>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.Cell align={"center"}>
                    {(rowData => {
                        if (rowData.Billed)
                            return (<CheckRoundIcon/>);
                        else return (<>Offen</>);
                    })}
                </Table.Cell>
            </Table.Column>
            <Table.Column>
                <Table.HeaderCell>Aktionen</Table.HeaderCell>
                <Table.Cell>
                    {rowData => {
                        if (!rowData.Billed) {
                            return (
                                <>
                                    <EditIcon className={'Table-Icon'}
                                              onClick={(e => handleEdit(rowData.ExpenseId, e))}/>
                                    <TrashIcon className={'Table-Icon'}
                                               onClick={(e => handleDelete(rowData.ExpenseId, e))}/>
                                </>
                            );
                        } else return (<></>);
                    }}
                </Table.Cell>
            </Table.Column>
        </Table>

    );
}

export default ExpenseOverview;
import React from 'react';
import {toast}  from 'react-toastify';

import {
    Button,
    ButtonToolbar,
    Col,
    DatePicker,
    DateRangePicker,
    Form,
    Grid,
    Input, MaskedInput,
    Row,
    Schema,
    SelectPicker
} from "rsuite";
import ProjectData from "../data/ProjectData";
import DateFormatter from "../helper/DateFormatter";

class ProjectExpenseForm extends React.Component {

    constructor(props) {
        super(props);

        this.formRef = React.createRef();
        this.model = Schema.Model({
            Date: Schema.Types.DateType().isRequired("Das ist ein Pflichtfeld"),
            SinglePrice: Schema.Types.NumberType().isRequired("Das ist ein Pflichtfeld"),
            Amount: Schema.Types.NumberType().min(1, "Das ist ein Pflichtfeld"),
            ProjectId: Schema.Types.NumberType().min(1, "Das ist ein Pflichtfeld"),
            Caption: Schema.Types.StringType().isRequired("Das ist ein Pflichtfeld")
        });
        this.state = {
            projectExpenseData: {
                ExpenseId: 0,
                Date: new Date(),
                SinglePrice: '',
                Amount: '',
                ProjectId: 0,
                Caption: ''
            },
            duration: "",
            customerName: "",
            projectsData: [],
            projectsOverviewData: [],
            formError: {}
        }
    }

    handleOpenProjectsDropdown = () => {
        if (this.state.projectsData.length === 0) {
            ProjectData.getProjectOverview().then(
                response => {
                    if(response.map) {
                        this.setState({
                            projectsOverviewData: response,
                            projectsData: (response.map(x => {
                                return {value: x.Id, label: x.Caption};
                            }))
                        });
                    }
                    else{
                        toast.error("Projekte konnten nicht geladen werden",{
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                });
        }
    }

    handleProjectChanged = (val, event) => {
        let project = this.state.projectsOverviewData.filter(x => x.Id === val)[0];
        if (project) {
            this.setState({customerName: project.CustomerName});

        } else this.setState({customerName: ""});

        if (typeof (this.props.hasOwnProperty("onProjectChanged") && this.props["onProjectChanged"]) === "function") {
            this.props.onProjectChanged(project);
        }
    }

    handleNewProjectExpense = () => {
        if (typeof (this.formRef.current) !== "undefined") {
            if (this.formRef.current.check()) {
                let peModel = {
                    ExpenseId: this.state.projectExpenseData.ExpenseId ?? 0,
                    InvoiceDate: this.state.projectExpenseData.Date,
                    Amount: this.state.projectExpenseData.Amount,
                    ProjectId: this.state.projectExpenseData.ProjectId,
                    Caption: this.state.projectExpenseData.Caption,
                    SinglePrice: this.state.projectExpenseData.SinglePrice
                };

                if (this.props.hasOwnProperty("onSave") && typeof (this.props.onSave) === "function") {
                    this.props.onSave(peModel, (success) => {
                        if (success) {
                            this.handleResetProjectExpense();
                        }
                    });
                }
            }
        }
    }

    handleResetProjectExpense = () => {
        this.setState({
            projectExpenseData: {
                ExpenseId: 0,
                Date: new Date(),
                SinglePrice: '',
                Amount: '',
                ProjectId: 0,
                Caption: ''
            },
            description: "",
            customerName: "",
            duration: ""
        });
    }

    setProjectExpenseData = (data) => {
        this.setState({projectExpenseData: data});
    }

    setFormError = (data) => {
        this.setState({formError: data});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.projectExpenseData !== prevProps.projectExpenseData) {
            this.handleOpenProjectsDropdown();
            if(this.props.hasOwnProperty("projectExpenseData")) {
                this.setState({
                    projectExpenseData: {
                        Id: this.props.projectExpenseData.Id,
                        Date: new Date(this.props.projectExpenseData.StartDate),
                        Amount: [new Date(this.props.projectExpenseData.StartTime), new Date(this.props.projectExpenseData.EndTime)],
                        ProjectId: this.props.projectExpenseData.ProjectId,
                        Caption: this.props.projectExpenseData.Description
                    },
                    description: this.props.projectExpenseData.Description
                });
            }
        }
    }

    render() {
        return (<div className={"ProjectExpense w-100"}>
            <Form formValue={this.state.projectExpenseData} onChange={this.setProjectExpenseData}
                  onCheck={this.setFormError}
                  model={this.model}
                  formError={this.state.formError}
                  ref={this.formRef}
                  checkTrigger={"change"}>
                <Grid fluid>
                    <Row>
                        <Col md={24}>
                            <Form.Group controlId={"ProjectId"}>
                                <Form.ControlLabel>Projekt</Form.ControlLabel>
                                <Form.Control name={"ProjectId"} accepter={SelectPicker}
                                              className={"Picker-100"}
                                              data={this.state.projectsData}
                                              onOpen={this.handleOpenProjectsDropdown}
                                              onChange={this.handleProjectChanged}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={24} className={"text-right text-secondary"}>
                            <span>{this.state.customerName}</span>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={24}>
                            <Form.Group controlId={"Caption"}>
                                <Form.ControlLabel>Bezeichnung</Form.ControlLabel>
                                <Form.Control name={"Caption"} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <Form.Group controlId={"Date"}>
                                <Form.ControlLabel>Datum</Form.ControlLabel>
                                <Form.Control name={"Date"} accepter={DatePicker}
                                              oneTap={true} showWeekNumbers={true}
                                              isoWeek={true} format={"dd.MM.yyyy"}/>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col md={24}>
                                    <Form.Group controlId={"Amount"}
                                                className={"Picker-100 Picker-Center"}>
                                        <Form.ControlLabel>Menge</Form.ControlLabel>
                                        <Form.Control name={"Amount"}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={24}>
                                    <Form.Group controlId={"SinglePrice"}
                                                className={"Picker-100 Picker-Center"}>
                                        <Form.ControlLabel>Preis</Form.ControlLabel>
                                        <Form.Control name={"SinglePrice"} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Grid>
            </Form>
            <Grid fluid>
                <Row>
                    <Col md={24}>
                        <ButtonToolbar>
                            <Button appearance={"primary"}
                                    onClick={this.handleNewProjectExpense}>Speichern</Button>
                            <Button appearance={"default"}
                                    onClick={this.handleResetProjectExpense}>Zurücksetzen</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Grid>
        </div>)
    }
}

export default ProjectExpenseForm;
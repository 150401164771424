import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {Button, ButtonToolbar, DatePicker, Form, Grid, Row, Col, SelectPicker, CheckPicker} from 'rsuite';

// Data
import InvoiceData from "../data/InvoiceData";
import ProjectData from "../data/ProjectData";
import CustomerData from "../data/CustomerData";
import {toast} from "react-toastify";

function InvoiceConfig() {
    let params = useParams();
    let [invoiceConfigData, setInvoiceConfigData] = React.useState({});
    let [customerPickerData, setCustomerPickerData] = React.useState([]);
    let [projectPickerData, setProjectPickerData] = React.useState([]);
    let [projectPickerDisabled, setProjectPickerDisabled] = React.useState(true);

    useEffect(() => {
        initializeData();
    }, []);

    const initializeData = async () => {
        if (params && params.hasOwnProperty("configId")) {
            let response = InvoiceData.getConfig(params.configId);
            setInvoiceConfigData(response);

            response = await ProjectData.getProjectsByCustomer(response.CustomerId);
            setProjectPickerData(
                response.map((x) => {
                    return {value: x.Id, label: x.Caption}
                })
            );

            response = await CustomerData.getCustomers();
            setCustomerPickerData(
                response.map((x) => {
                    return {value: x.Id, label: x.Name}
                })
            );
        }
    }

    async function handleCustomerPickerOpen() {
        if (customerPickerData.length === 0) {
            let response = await CustomerData.getCustomers();

            if (!response)
                toast("Kunden konnten nicht geladen werden", {
                    type: "error"
                })

            setCustomerPickerData(
                response.map((x) => {
                    return {value: x.Id, label: x.Name}
                })
            );
        }
    }

    async function handleCustomerChange(val){
        console.log(val);
        if(val){
            setProjectPickerDisabled(false);
        }
        else{
            setProjectPickerDisabled(true);
        }

        setProjectPickerData([]);

        let invoiceConfig = invoiceConfigData;
        invoiceConfig.CustomerId = val;
        invoiceConfig.ProjectIds = [];
        setInvoiceConfigData(invoiceConfig);

    }

    async function handleProjectPickerOpen() {
        if (invoiceConfigData.CustomerId) {
            let response = await ProjectData.getProjectsByCustomer(invoiceConfigData.CustomerId);

            setProjectPickerData(
                response.map((x) => {
                    return {value: x.Id, label: x.Caption}
                })
            );
        } else {
            toast("Bitte wählen Sie einen Kunden aus!", {
                type: "warning"
            });
        }
    }

    async function handleSave(){
        InvoiceData.addOrUpdateConfig(invoiceConfigData);
    }

    return (
        <>
            <h1>Rechnungskonfiguration</h1>
            <Form formValue={invoiceConfigData} onChange={setInvoiceConfigData}>
                <Grid fluid>
                    <Row className={"mb-2"}>
                        <Col md={24}>
                            <Form.Group controlId={"Caption"}>
                                <Form.ControlLabel>Bezeichnung</Form.ControlLabel>
                                <Form.Control name={"Caption"} placeholder={"Neue Rechnungskonfiguration"}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <Form.Group controlId={"CustomerId"}>
                                <Form.ControlLabel>Kunde</Form.ControlLabel>
                                <Form.Control name={"CustomerId"} accepter={SelectPicker} data={customerPickerData}
                                              onOpen={handleCustomerPickerOpen}
                                              className={"Picker-100"} placeholder={"Kunde auswählen"}
                                onChange={(val, event) => handleCustomerChange(val)}/>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId={"ProjectIds"}>
                                <Form.ControlLabel>Zu verrechnende Projekte</Form.ControlLabel>
                                <Form.Control name={"ProjectIds"} accepter={CheckPicker} data={projectPickerData}
                                              onOpen={handleProjectPickerOpen}
                                              className={"Picker-100"}
                                              disabled={projectPickerDisabled} placeholder={"Projekte auswählen"}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
            <ButtonToolbar>
                <Button appearance={"primary"} onClick={handleSave}>Speichern</Button>
                <Button>Abbrechen</Button>
            </ButtonToolbar>
        </>
    );
}

export default InvoiceConfig;
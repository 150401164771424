import React, {useState} from 'react';

// styles
import "../assets/TimeTracking.css";
import EventEmitter from "events";
import ExpenseOverview from "../components/ExpenseOverview";
import ProjectData from "../data/ProjectData";
import {toast} from "react-toastify";
import {Col, Dropdown, Grid, Row, SelectPicker} from "rsuite";
import ExpenseForm from "../components/ExpenseForm";

function Expenses() {
    let [projectId, setProjectId] = useState(0);
    let [projectsData, setProjectsData] = useState([]);

    const projectExpenseEmitter = new EventEmitter();

    function handleOpenProjectsDropdown() {
        if (projectsData.length === 0) {
            ProjectData.getProjectOverview().then(
                response => {
                    if (response.map) {

                        setProjectsData(response.map(x => {
                            return {value: x.Id, label: x.Caption};
                        }))
                    } else {
                        toast.error("Projekte konnten nicht geladen werden", {
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                });
        }
    }

    function handleProjectChanged(val, event) {
        setProjectId(val);
        console.log(val);
    }

    function handleNewProjectExpense(peModel, callback) {
        console.log(peModel);
        ProjectData.addOrUpdateProjectExpense(peModel, (res) => {
            if (res.success) {
                callback(true);
                projectExpenseEmitter.emit("update");
            }
        });
    }

    return (
        <>
            <h1>Projektausgaben</h1>
            <Grid fluid>
                <Row className={"mb-2"}>
                    <Col md={8}>
                        <ExpenseForm onSave={handleNewProjectExpense}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <SelectPicker placeholder={"Projekt wählen"} size={"lg"} data={projectsData}
                                      onChange={handleProjectChanged} onOpen={handleOpenProjectsDropdown}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={24}>
                        <ExpenseOverview eventEmitter={projectExpenseEmitter} projectId={projectId}/>
                    </Col>
                </Row>
            </Grid>
        </>
    );
}

export default Expenses;
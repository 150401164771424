import React, {useEffect} from 'react';
import {Button, Modal, Table} from "rsuite";
import Swal from 'sweetalert2';

// Icons
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

import DateFormatter from "../helper/DateFormatter";

// Data
import TimeTrackingData from "../data/TimeTrackingData";
import TimeTrackingForm from "./TimeTrackingForm";

/***
 * @property onSave
 * @property timeTrackingId
 */
class TimeTrackingModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            timeTrackingData: {},
            open: (props["open"] ? props["open"] : false)
        }
    }

    setTimeTrackingForm = async () => {
        if(this.props.timeTrackingId) {
            let response = await TimeTrackingData.getTimeTracking(this.props.timeTrackingId);
            this.setState({
                timeTrackingData: response
            });
            console.log(response);
        }
    }

    handleOpen = () => {
        this.setTimeTrackingForm();
    }

    handleClose = () => {
        this.setState({open: false})
        if(this.props.hasOwnProperty("onClose"))
            this.props.onClose();
    }

    handleSave = () => {

    }

    componentDidMount() {
        this.setTimeTrackingForm();
    }

    componentDidUpdate(prevProps) {
        if (this.props.open !== prevProps.open) {
            this.setState({open: this.props.open});
        }
    }

    render() {
        return (
            <>
                <Modal open={this.state.open} onClose={this.handleClose} size="lg" onOpen={this.handleOpen}>
                    <Modal.Header>
                        <Modal.Title>Zeiterfassung bearbeiten</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{minHeight: 300}}>
                        <TimeTrackingForm timeTrackingData={this.state.timeTrackingData} onSave={this.props.onSave}/>
                    </Modal.Body>
                </Modal>
            </>
        );
    }

}

export default TimeTrackingModal;
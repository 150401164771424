import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    url: 'https://identity.pritz-it.com/',
    realm: 'pritzit-business-suite',
    clientId: 'business-suite',

});

export default keycloak;

import React, {useEffect} from 'react';
import {
    Grid, Row, Col, Form, Input
} from "rsuite";
import FormControl from "rsuite/FormControl";
import FormControlLabel from "rsuite/FormControlLabel";

// Customs
import TimeTrackingOverview from '../components/TimeTrackingOverview';
import MonthlyOverview from '../widgets/MonthlyOverview';

// data
import TimeTrackingData from "../data/TimeTrackingData";

// styles
import "../assets/TimeTracking.css";
import TimeTrackingForm from "../components/TimeTrackingForm";
import EventEmitter from "events";

function TimeTracking() {
    const TextArea = React.forwardRef((props, ref) =>
        <Input as="textarea" {...props} ref={ref}/>
    );

    const timeTrackingEmitter = new EventEmitter();

    let [projectData, setProjectData] = React.useState({
        Id: "",
        Caption: "Kein Projekt ausgewählt",
        CustomerId: "",
        CustomerName: "",
        Description: "",
        ProjectStatus: "",
        ProjectStatusCaption: "",
        Rate: "",
        TotalDuration: "",
        BillingType: "",
        BillingTypeCaption: "",
        ProjectType: "",
        ProjectTypeCaption: "",
    });
    let [currHoursDay, setCurrHoursDay] = React.useState(0);

    function handleNewTimeTracking(ttModel, callback) {
        TimeTrackingData.addOrUpdateTimeTracking(ttModel, (res) => {
            if (res.success) {
                callback(true);
                timeTrackingEmitter.emit("updateTimeTracking");
                timeTrackingEmitter.emit("updateMonthlyOverview");
                handleProjectChanged(null);
            }
        });
    }

    function handleProjectChanged(newProject) {
        if(!newProject) {
            newProject = {
                Id: "",
                Caption: "Kein Projekt ausgewählt",
                CustomerId: "",
                CustomerName: "",
                Description: "",
                ProjectStatus: "",
                ProjectStatusCaption: "",
                Rate: "",
                TotalDuration: "",
                BillingType: "",
                BillingTypeCaption: "",
                ProjectType: "",
                ProjectTypeCaption: "",
            };
        }

        setProjectData(newProject);
    }

    return (
        <div className={"mb-2"}>
            <h1>Zeiterfassung</h1>
            <Grid fluid>
                <Row>
                    <Col lg={8} md={12}>
                        <TimeTrackingForm onSave={handleNewTimeTracking} onProjectChanged={handleProjectChanged} className={"mb-2"}/>
                        <MonthlyOverview eventEmitter={timeTrackingEmitter}/>
                    </Col>
                    <Col lg={16} md={12} className={"h-100"}>
                        <div className={"ProjectInfo w-100 h-100"}>
                            <Form formValue={projectData} readOnly={true}>
                                <Grid fluid={true}>
                                    <Row>
                                        <Col md={24}>
                                            <FormControl name={"Caption"} plaintext className={"text-bold h3 border-bottom-solid"}/>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-2"}>
                                        <Col md={12}>
                                            <Form.Group controlId={"CustomerName"}>
                                                <FormControlLabel className={"text-bold"}>Kunde</FormControlLabel>
                                                <FormControl name={"CustomerName"} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group controlId={"ProjectStatus"}>
                                                <FormControlLabel className={"text-bold"}>Status</FormControlLabel>
                                                <FormControl name={"ProjectStatusCaption"} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-2"}>
                                        <Col md={12}>
                                            <Form.Group controlId={"ProjectTypeCaption"}>
                                                <FormControlLabel className={"text-bold"}>Projekt-Typ</FormControlLabel>
                                                <FormControl name={"ProjectTypeCaption"}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group controlId={"BillingTypeCaption"}>
                                                <FormControlLabel className={"text-bold"}>Verrechnungsart</FormControlLabel>
                                                <FormControl name={"BillingTypeCaption"}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-2"}>
                                        <Col md={12}>
                                            <Form.Group controlId={"Rate"}>
                                                <FormControlLabel className={"text-bold"}>Stundensatz</FormControlLabel>
                                                <FormControl name={"Rate"} prefix={"€"}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group controlId={"TotalDuration"}>
                                                <FormControlLabel className={"text-bold"}>Stunden Gesamt</FormControlLabel>
                                                <FormControl name={"TotalDuration"}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-2"}>
                                        <Col md={24}>
                                            <Form.Group controlId={"Description"}>
                                                <FormControlLabel className={"text-bold"}>Beschreibung</FormControlLabel>
                                                <FormControl name={"Description"} className={"w-100"} accepter={TextArea} rows={7}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Grid>
                            </Form>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <TimeTrackingOverview eventEmitter={timeTrackingEmitter} autoHeight={true}/>
                </Row>
            </Grid>
        </div>
    );
}

export default TimeTracking;
import React, {useEffect} from 'react';
import {Button, ButtonToolbar, InputPicker, List, Modal, FlexboxGrid, Grid, Row, Col} from "rsuite";
import {toast} from "react-toastify";
import ProjectData from "../data/ProjectData";

function TaskView(props) {
    let [projectId, setProjectId] = React.useState(props["projectId"] ?? 0);
    let [taskData, setTaskData] = React.useState([]);
    let [projectTaskData, setProjectTaskData] = React.useState([]);
    let [open, setOpen] = React.useState(false);
    let [task, setTask] = React.useState(null);

    useEffect(() => {
        initializeData();
    }, []);

    async function initializeData() {
        let res = await ProjectData.getTaskForProject(projectId);
        if (res.success) {
            setProjectTaskData(res.data);
        }
    }

    function handleClose() {
        setOpen(false);
    }

    function handleClean() {
        setTask(null);
    }

    function handleCreate(newTaskId, item) {
        setTask(newTaskId)
    }

    async function handleSearch(searchKeyword) {
        let response = await ProjectData.getAllTask(searchKeyword);
        console.log("TaskData: " + Date.now());
        console.log(taskData);
        console.log("SearchResponse (" + response.data.length + "): " + Date.now());
        if (response.data.length) {
            console.log(response.data);
            setTaskData(response.data);
        }
    }

    function handleSave() {
        console.log(taskData);
        let taskModel = null;
        if (taskData.length) {
            taskModel = taskData.find((t) => t.Id === task);
        }

        if (!taskModel)
            taskModel = {Id: task, Caption: task};

        if (taskModel.Id === taskModel.Caption) {
            ProjectData.addOrUpdateTask(projectId, taskModel, (res) => {
                if (res.success)
                    toast.success("Aufgabe erfolgreich angelegt und zum Projekt hinzugefügt");
                setOpen(false);
            });
        } else {
            ProjectData.addTaskToProject(projectId, taskModel.Id, (res) => {
                if (res.success)
                    toast.success("Aufgabe erfolgreich zum Projekt hinzugefügt");
                setOpen(false);
            });
        }
    }

    return (
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <div className={"mb-2"}>
                        <Button onClick={e => setOpen(true)} appearance={"primary"}>Aufgabe hinzufügen</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={6}>

                    <div className={"mb-2"}>
                        <List hover bordered>
                            {
                                (projectTaskData && projectTaskData.length) ?
                                    (projectTaskData.map((item, index) => (
                                        <List.Item key={item.Caption} index={item.TaskId}>
                                            <FlexboxGrid>
                                                {/*base info*/}
                                                <FlexboxGrid.Item
                                                    colspan={19}
                                                    style={{
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <div style={{fontWeight: "bold"}}>{item.Caption}</div>
                                                </FlexboxGrid.Item>
                                                {/*uv data*/}
                                                <FlexboxGrid.Item colspan={2}>
                                                    <a href="#">View</a>
                                                    <span style={{padding: 5}}></span>
                                                    <a href="#">Edit</a>
                                                </FlexboxGrid.Item>
                                            </FlexboxGrid>
                                        </List.Item>
                                    ))) : <List.Item>Keine Aufgaben</List.Item>
                            }
                        </List>
                    </div>
                </Col>
            </Row>
            <Modal backdrop={"static"} keyboard={true} open={open} onClose={handleClose} size={"xs"}>
                <Modal.Header>
                    <Modal.Title>Aufgabe hinzufügen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label style={{display: "block"}}>Bezeichnung</label>
                    <InputPicker
                        data={taskData}
                        block={true}
                        cleanable={true}
                        creatable={true}
                        labelKey={"Caption"}
                        valueKey={"Id"}
                        value={task}
                        onClean={handleClean}
                        onCreate={handleCreate}
                        onSearch={handleSearch}
                        onChange={setTask}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar>
                        <Button onClick={handleSave} appearance={"primary"}>Speichern</Button>
                        <Button onClick={handleClose} appearance={"default"}>Abbrechen</Button>
                    </ButtonToolbar>
                </Modal.Footer>
            </Modal>
        </Grid>
    );
}

export default TaskView;
import React, {useEffect} from 'react';
import {Button, Modal, SelectPicker, DateRangePicker, Form, ButtonToolbar} from "rsuite";
import Swal from 'sweetalert2';

// Icons
import CustomerData from "../data/CustomerData";
import InvoiceData from "../data/InvoiceData";
import {toast} from "react-toastify";

/***
 * @property onSave
 * @property timeTrackingId
 */
function NewInvoiceModal(props) {
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    let [open, setOpen] = React.useState(false);
    let [generateBusy, setGenerateBusy] = React.useState(false);
    let [formData, setFormData] = React.useState({
        Customer: null,
        DateRange: [firstDay, lastDay]
    });
    let [customerPickerData, setCustomerPickerData] = React.useState([]);
    let [projectPickerData, setProjectPickerData] = React.useState([]);

    useEffect(() => {
        initializeData();
    }, []);

    function handleComplete(){
        handleClose();
        // Fire Events
        if(typeof(props.onComplete) == "function")
            props.onComplete();
    }

    const initializeData = async () => {
        let response = await CustomerData.getCustomers();
        setCustomerPickerData(
            response.map((x) => {
                return {value: x.Id, label: x.Name}
            })
        );
    }

    async function handleCustomerPickerOpen() {
        if (customerPickerData.length === 0) {
            let response = await CustomerData.getCustomers();

            if (!response)
                toast("Kunden konnten nicht geladen werden", {
                    type: "error"
                })

            setCustomerPickerData(
                response.map((x) => {
                    return {value: x.Id, label: x.Name}
                })
            );
        }
    }

    function handleClose() {
        setOpen(false);
        setFormData({Customer: null, DateRange: [firstDay, lastDay]});
    }

    function handleGenerate() {
        setGenerateBusy(true);
        InvoiceData.generateInvoiceFromCustomer(
            formData,
            (res) => {
                console.log(res);
                setGenerateBusy(false);
                if (res.success) {
                    toast(`Rechnung ${res.data.InvoiceNo} erfolgreich erstellt`, {type: "success"});
                    handleComplete();
                } else {
                    Swal.fire({
                        html: res.message,
                        icon: "warning"
                    });
                }
            }
        );
    }

    return (
        <>
            <Button appearance={"primary"} onClick={() => {
                setOpen(true)
                setGenerateBusy(false);
            }}>
                Neue Rechnung erstellen
            </Button>
            <Modal open={open} onClose={handleClose} size="md">
                <Modal.Header>
                    <Modal.Title>Rechnung generieren</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form formValue={formData} className={"mb-2"} onChange={setFormData}>
                        <Form.Group controlId={"Customer"}>
                            <Form.ControlLabel>
                                Kunde
                            </Form.ControlLabel>
                            <Form.Control name={"Customer"} accepter={SelectPicker} className={"Picker-100"}
                                          data={customerPickerData} onOpen={handleCustomerPickerOpen}/>
                        </Form.Group>
                        <Form.Group controlId={"DateRange"}>
                            <Form.ControlLabel>
                                Zeitraum
                            </Form.ControlLabel>
                            <Form.Control name={"DateRange"} accepter={DateRangePicker} className={"Picker-100"}
                                          format={"dd.MM.yyyy"}/>
                        </Form.Group>
                    </Form>
                    <ButtonToolbar>
                        <Button appearance={"primary"} onClick={handleGenerate} loading={generateBusy}>Erstellen</Button>
                        <Button onClick={handleClose}>Abbrechen</Button>
                    </ButtonToolbar>
                </Modal.Body>
            </Modal>
        </>
    );

}

export default NewInvoiceModal;
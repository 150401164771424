import React from 'react';
import {toast}  from 'react-toastify';

import {
    Button,
    ButtonToolbar,
    Col,
    DatePicker,
    DateRangePicker,
    Form,
    Grid,
    Input,
    Row,
    Schema,
    SelectPicker
} from "rsuite";
import ProjectData from "../data/ProjectData";
import DateFormatter from "../helper/DateFormatter";

class TimeTrackingForm extends React.Component {


    constructor(props) {
        super(props);
        this.className = "TimeTracking w-100";

        if(this.props.hasOwnProperty("className")){
            this.className += ` ${this.props.className}`;
        }

        this.formRef = React.createRef();
        this.model = Schema.Model({
            StartDate: Schema.Types.DateType().isRequired("Das ist ein Pflichtfeld"),
            TimeRange: Schema.Types.ArrayType().isRequired("Das ist ein Pflichtfeld"),
            ProjectId: Schema.Types.NumberType().min(1, "Das ist ein Pflichtfeld")
        });
        this.state = {
            timeTrackingData: {
                StartDate: new Date(),
                TimeRange: null,
                ProjectId: 0,
                Ref: "",
                Description: ''
            },
            description: "",
            duration: "",
            customerName: "",
            projectsData: [],
            projectsOverviewData: [],
            formError: {}
        }
    }

    handleOpenProjectsDropdown = () => {
        if (this.state.projectsData.length === 0) {
            ProjectData.getProjectOverview().then(
                response => {
                    if(response.map) {
                        this.setState({
                            projectsOverviewData: response,
                            projectsData: (response.map(x => {
                                return {value: x.Id, label: x.Caption};
                            }))
                        });
                    }
                    else{
                        toast.error("Projekte konnten nicht geladen werden",{
                            position: toast.POSITION.TOP_RIGHT,
                        })
                    }
                });
        }
    }

    handleProjectChanged = (val, event) => {
        let project = this.state.projectsOverviewData.filter(x => x.Id === val)[0];
        if (project) {
            this.setState({customerName: project.CustomerName});

        } else this.setState({customerName: ""});

        if (typeof (this.props.hasOwnProperty("onProjectChanged") && this.props["onProjectChanged"]) === "function") {
            this.props.onProjectChanged(project);
        }
    }

    handleTimeRangeChanged = (val, event) => {
        if (!val || val.length === 0)
            return;

        let startTime = val[0];
        let endTime = val[1];

        let dur = DateFormatter.getDuration(startTime, endTime);
        this.setState({duration: dur});
    }

    handleDescriptionChanged = (val) => {
        let data = this.state.timeTrackingData;
        data.Description = val;
        this.setState({timeTrackingData: data, description: val});
    }

    handleNewTimeTracking = () => {
        if (typeof (this.formRef.current) !== "undefined") {
            if (this.formRef.current.check()) {
                let ttModel = {
                    Id: this.state.timeTrackingData.Id ?? 0,
                    StartDate: this.state.timeTrackingData.StartDate,
                    StartTime: this.state.timeTrackingData.TimeRange[0],
                    EndTime: this.state.timeTrackingData.TimeRange[1],
                    ProjectId: this.state.timeTrackingData.ProjectId,
                    Ref: this.state.timeTrackingData.Ref,
                    Description: this.state.timeTrackingData.Description
                };

                if (this.props.hasOwnProperty("onSave") && typeof (this.props.onSave) === "function") {
                    this.props.onSave(ttModel, (success) => {
                        if (success) {
                            this.handleResetTimeTracking();
                        }
                    });
                }
            }
        }
    }

    handleResetTimeTracking = () => {
        this.setState({
            timeTrackingData: {
                StartDate: new Date(),
                TimeRange: null,
                ProjectId: 0,
                Ref: "",
                Description: ''
            },
            description: "",
            duration: "",
            customerName: ""
        });
    }

    setTimeTrackingData = (data) => {
        this.setState({timeTrackingData: data});
    }

    setFormError = (data) => {
        this.setState({formError: data});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.timeTrackingData !== prevProps.timeTrackingData) {
            this.handleOpenProjectsDropdown();
            this.setState({
                timeTrackingData: {
                    Id: this.props.timeTrackingData.Id,
                    StartDate: new Date(this.props.timeTrackingData.StartDate),
                    TimeRange: [new Date(this.props.timeTrackingData.StartTime), new Date(this.props.timeTrackingData.EndTime)],
                    ProjectId: this.props.timeTrackingData.ProjectId,
                    Ref: this.props.timeTrackingData.Ref,
                    Description: this.props.timeTrackingData.Description
                },
                description: this.props.timeTrackingData.Description
            });
        }
    }

    render() {
        return (
            <div className={this.className}>
            <Form formValue={this.state.timeTrackingData} onChange={this.setTimeTrackingData}
                  onCheck={this.setFormError}
                  model={this.model}
                  formError={this.state.formError}
                  ref={this.formRef}
                  checkTrigger={"change"}>
                <Grid fluid>
                    <Row>
                        <Col md={24}>
                            <Form.Group controlId={"ProjectId"}>
                                <Form.ControlLabel>Projekt</Form.ControlLabel>
                                <Form.Control name={"ProjectId"} accepter={SelectPicker}
                                              className={"Picker-100"}
                                              data={this.state.projectsData}
                                              onOpen={this.handleOpenProjectsDropdown}
                                              onChange={this.handleProjectChanged}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={24} className={"text-right text-secondary"}>
                            <span>{this.state.customerName}</span>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <Form.Group controlId={"StartDate"}>
                                <Form.ControlLabel>Datum</Form.ControlLabel>
                                <Form.Control name={"StartDate"} accepter={DatePicker}
                                              oneTap={true} showWeekNumbers={true}
                                              isoWeek={true} format={"dd.MM.yyyy"}/>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Row>
                                <Col md={24}>
                                    <Form.Group controlId={"TimeRange"}
                                                className={"Picker-100 Picker-Center"}>
                                        <Form.ControlLabel>Start-Zeit - End-Zeit</Form.ControlLabel>
                                        <Form.Control name={"TimeRange"} accepter={DateRangePicker}
                                                      format={"HH:mm"} character={" — "}
                                                      onChange={this.handleTimeRangeChanged}/>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={24} className={"text-right text-secondary"}>
                                    <span>{this.state.duration}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={6}>
                            <Form.Group controlId={"Ref"}>
                                <Form.ControlLabel>Ref</Form.ControlLabel>
                                <Form.Control name={"Ref"} style={{maxWidth: "200px"}}/>
                            </Form.Group>
                        </Col>
                        <Col md={18}></Col>
                    </Row>
                </Grid>
            </Form>
            <Grid fluid>
                <Row className={"mb-2"}>
                    <Col md={24}>
                        <label>Tätigkeit</label>
                        <Input as={"textarea"} rows={4} onChange={this.handleDescriptionChanged}
                               value={this.state.description}/>
                    </Col>
                </Row>
                <Row>
                    <Col md={24}>
                        <ButtonToolbar>
                            <Button appearance={"primary"}
                                    onClick={this.handleNewTimeTracking}>Speichern</Button>
                            <Button appearance={"default"}
                                    onClick={this.handleResetTimeTracking}>Zurücksetzen</Button>
                        </ButtonToolbar>
                    </Col>
                </Row>
            </Grid>
        </div>)
    }
}

export default TimeTrackingForm;
import React, {useEffect} from 'react';
import ProjectData from "../data/ProjectData";
import {useNavigate, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import {Grid, Row, Col, Form, InputNumber, Input, SelectPicker, Button, ButtonToolbar} from "rsuite";
import CustomerData from "../data/CustomerData";
import TimeTrackingOverview from "../components/TimeTrackingOverview";
import TaskView from "../components/TaskView";


function Project() {
    let [formData, setFormData] = React.useState({
        Id: 0,
        CustomerId: null,
        Caption: "",
        Description: null,
        ProjectStatus: null,
        ProjectType: null,
        BillingType: null,
        Rate: null
    });

    let [description, setDescription] = React.useState("");

    let [title, setTitle] = React.useState("Projekt");
    let [customerPickerData, setCustomerPickerData] = React.useState([]);
    let [projectTypeData, setProjectTypeData] = React.useState([]);
    let [billingTypeData, setBillingTypeData] = React.useState([]);
    let [statusPickerData, setStatusPickerData] = React.useState([]);

    let {type, id} = useParams();
    let [viewMode] = React.useState(type === "view");
    const navigate = useNavigate();

    let footer;
    let taskView;

    if (!viewMode) {
        taskView = "";
        footer =
            <>
                <ButtonToolbar>
                    <Button appearance={"primary"} onClick={handleSave}>Speichern</Button>
                    <Button appearance={"default"} onClick={handleCancel}>Abbrechen</Button>
                </ButtonToolbar>
            </>
    } else {
        taskView = (
            <div>
                <h4>Zugeordnete Aufgaben</h4>
                <TaskView projectId={id}/>
            </div>
        );
        footer = (
            <div>
                <h4>Zeiterfassung</h4>
                <TimeTrackingOverview projectId={id} height={400}/>
            </div>
        );
    }

    function handleOpenProjectType() {
        if (projectTypeData.length === 0) {
            ProjectData.getAllProjectType().then((res) => {
                setProjectTypeData(res.map((x) => {
                        return {value: x.Id, label: x.Caption};
                    })
                );
            });
        }
    }

    function handleOpenBillingType() {
        if (projectTypeData.length === 0) {
            ProjectData.getAllBillingType().then((res) => {
                setBillingTypeData(res.map((x) => {
                        return {value: x.Id, label: x.Caption};
                    })
                );
            });
        }
    }

    function handleOpenCustomer() {
        if (customerPickerData.length === 0) {
            CustomerData.getCustomers().then((res) => {
                setCustomerPickerData(res.map((x) => {
                        return {value: x.Id, label: x.Name};
                    })
                );
            });
        }
    }

    function handleOpenProjectStatus() {
        if (customerPickerData.length === 0) {
            ProjectData.getAllStatus().then((res) => {
                setStatusPickerData(res.map((x) => {
                        return {value: x.Id, label: x.Caption};
                    })
                );
            });
        }
    }

    function handleSave() {
        ProjectData.addOrUpdateProject(formData, (res) => {
            if (res.success) {
                Swal.fire(
                    "Erfolgreich gespeichert",
                    `${formData.Caption} wurde erfolgreich gespeichert`,
                    "success"
                ).then(() => {
                    navigate("/projects");
                });
            } else {
                Swal.fire(
                    "Fehler beim speichern",
                    `${formData.Caption} konnte nicht gespeichert werden`,
                    "Error"
                );
            }
        });
    }

    function handleCancel() {
        Swal.fire({
            title: "Wirklich abbrechen?",
            text: `Alle ungespeicherten Daten gehen verloren`,
            icon: "question",
            confirmButtonText: 'Ja',
            cancelButtonText: "Nein",
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed)
                navigate("/projects");
        });
    }

    const initializeProjectData = async () => {
        let response;

        handleOpenProjectStatus();
        handleOpenCustomer();
        handleOpenProjectType();
        handleOpenBillingType();

        switch (type) {
            case "new":
                setTitle("Neues Projekt");
                break;
            case "edit":
                response = await ProjectData.getProject(id);
                setFormData(await response);
                setTitle(response.Caption);
                setDescription(response.Description);
                break;
            case "view":
                response = await ProjectData.getProject(id);
                if (!response.Description)
                    response.Description = 'Keine Beschreibung';

                setFormData(response);
                setTitle(response.Caption);
                setDescription(response.Description);
                break;
            default:
                navigate("/projects");
        }
    }

    useEffect(() => {
        initializeProjectData();
    }, []);

    function handleDescriptionChanged(val) {
        setDescription(val);

        let data = formData;
        data.Description = val;
        setFormData(data);
    }

    return (
        <>
            <h1>{title}</h1>
            <Form plaintext={viewMode} fluid={true} formValue={formData} onChange={setFormData}>
                <Grid fluid>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <Form.Group controlId={"Id"} className={"Subtle-Form-Group"}>
                                <Form.ControlLabel>Projekt-Nummer</Form.ControlLabel>
                                <Form.Control name={"Id"} plaintext={true}/>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId={"ProjectStatus"}>
                                <Form.ControlLabel>Status</Form.ControlLabel>
                                <Form.Control name={"ProjectStatus"} accepter={SelectPicker}
                                              onOpen={handleOpenProjectStatus}
                                              data={statusPickerData} className={"Picker-100"}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={24}>
                            <Form.Group controlId={"Caption"}>
                                <Form.ControlLabel>Bezeichnung</Form.ControlLabel>
                                <Form.Control name={"Caption"} placeholder={"Neues Projekt"}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={24}>
                            <Form.Group controlId={"CustomerReference"}>
                                <Form.ControlLabel>Kundenreferenz</Form.ControlLabel>
                                <Form.Control name={"CustomerReference"} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <Form.Group controlId={"ProjectType"}>
                                <Form.ControlLabel>Projekt-Art</Form.ControlLabel>
                                <Form.Control name={"ProjectType"} accepter={SelectPicker}
                                              onOpen={handleOpenProjectType}
                                              data={projectTypeData} className={"Picker-100"}/>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId={"BillingType"}>
                                <Form.ControlLabel>Zahlungsart</Form.ControlLabel>
                                <Form.Control name={"BillingType"} accepter={SelectPicker}
                                              onOpen={handleOpenBillingType}
                                              data={billingTypeData} className={"Picker-100"}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col md={12}>
                            <Form.Group controlId={"CustomerId"}>
                                <Form.ControlLabel>Kunde</Form.ControlLabel>
                                <Form.Control name={"CustomerId"} accepter={SelectPicker} onOpen={handleOpenCustomer}
                                              data={customerPickerData} className={"Picker-100"}/>
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId={"Rate"}>
                                <Form.ControlLabel>Stundensatz</Form.ControlLabel>
                                <Form.Control name={"Rate"} accepter={InputNumber} prefix={"€"}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
            <label>Beschreibung</label>
            <div className={"mb-2"}>
                <Input as={"textarea"} rows={7} name={"Description"} value={description} plaintext={viewMode}
                       onChange={(val) => handleDescriptionChanged(val)}/>
            </div>
            {taskView}
            {footer}
        </>
    );
}

export default Project;
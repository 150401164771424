export default {
    handleLogin(loginData){
        console.log("Login");
        this.setSessionToken("TEST");
    },
    handleLogout(){
        console.log("Logout");
    },
    getSessionToken(){
        return sessionStorage.getItem("sessionToken");
    },
    setSessionToken(token){
        sessionStorage.setItem("sessionToken", token);
    }
}
import axios from 'axios';

let ProjectData = {
    getProjects: async function () {
        let response = await axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/project');
        return await response.data;
    },
    getProjectOverview: async function () {
        let response = await axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/projectoverview');
        return await response.data;
    },
    getProjectsByCustomer: async function(customerId){
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/projectbycustomer/${customerId}`);
        return await response.data;
    },
    getProject: async function (projectId) {
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/project/${projectId}`);
        return (await response.data)[0];
    },
    getAllStatus: async function (){
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/projectstatus`);

        return await response.data;
    },
    getStatus: async function (statusId){
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/projectstatus/${statusId}`);

        return (await response.data)[0];
    },
    getAllProjectType: async function (){
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/projecttype`);

        return await response.data;
    },
    getProjectType: async function (typeId){
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/projecttype/${typeId}`);

        return (await response.data)[0];
    },
    getAllBillingType: async function (){
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/billingtype`);

        return await response.data;
    },
    getBillingType: async function (typeId){
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/billingtype/${typeId}`);

        return (await response.data)[0];
    },
    addOrUpdateProject: async function (projectModel, callback) {
        if (projectModel.Id > 0) {
            let projectId = projectModel.Id;
            delete projectModel.Id;

            axios.put(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/project',
                {projectId: projectId, projectModel: projectModel}
            )
                .then((res) => {
                    callback(res.data)
                });
        }
        else {
            delete projectModel.Id;
            axios.post(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/project', {projectModel})
                .then((res) => {
                    callback(res.data)
                });
        }
    },
    deleteProject: async function (id, callback){
        axios.delete(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/project/' + id)
            .then((res) => {
                callback(res.data)
            });
    },

    addOrUpdateTask: async function (projectId, taskModel, callback){
        if(taskModel.Id === taskModel.Caption)
            //insert
            axios.post(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/task',
                {caption: taskModel.Caption, projectId: projectId})
                .then((res) => {
                    callback(res.data)
            });
        else
            axios.put(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/task',
                {taskId: taskModel.Id, caption: taskModel.Caption, projectId: projectId})
                .then((res) => {
                    callback(res.data)
                });
    },
    addTaskToProject: async function (projectId, taskId, callback){
        axios.post(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/tasktoproject',
            {taskId: taskId, projectId: projectId})
            .then((res) => {
                callback(res.data)
            });
    },
    getTaskForProject: async function (projectId){
        let res = await axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/taskfromproject/'+projectId)
        return await res.data;
    },
    getAllTask: async function (searchQuery = ""){
        if(searchQuery)
            searchQuery = "?q="+searchQuery;
        let response = await axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/task'+searchQuery);
        return await response.data;
    },
    getProjectExpenses:  async function(projectId){
        let response = await axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/projectexpenses/'+projectId);
        return (await response.data).data;
    },
    deleteProjectExpense: async function (projectExpenseId, callback){
        axios.delete(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/projectexpenses/' + projectExpenseId)
            .then((res) => {
                callback(res.data)
            });
    },
    addOrUpdateProjectExpense: async function (projectExpenseModel, callback) {
        if (projectExpenseModel.ExpenseId > 0) {
            let projectExpenseId = projectExpenseModel.Id;
            delete projectExpenseModel.ExpenseId;

            axios.put(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/projectexpenses',
                {expenseId: projectExpenseId, projectExpenseModel: projectExpenseModel}
            )
                .then((res) => {
                    callback(res.data)
                });
        }
        else {
            delete projectExpenseModel.ExpenseId;
            axios.post(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/projectexpenses', {projectExpenseModel})
                .then((res) => {
                    callback(res.data)
                });
        }
    },
}

export default ProjectData;

import React, {useState} from 'react';
import './assets/App.css';
import Header from './components/Header';
import {Outlet, useNavigate} from "react-router-dom";
import {Sidenav, Nav, Dropdown, Grid, Row, Col, Stack, Button, Modal} from 'rsuite';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// Styles
import 'rsuite/dist/rsuite.min.css';

// Icons
import PeoplesCustomizeIcon from '@rsuite/icons/PeoplesCostomize';
import ProjectIcon from '@rsuite/icons/Project'
import TimeIcon from '@rsuite/icons/Time';
import CreditCardPlusIcon from '@rsuite/icons/CreditCardPlus';
import PlusIcon from '@rsuite/icons/Plus';
import OffIcon from '@rsuite/icons/Off';
import {Turn as Hamburger} from 'hamburger-react'
import LoginView from "./components/Login/Login.view";
import {useKeycloak} from '@react-keycloak/web';

function App() {
    let navigate = useNavigate();

    let {keycloak} = useKeycloak();

    function handleNavChange(eventKey, event) {
        if(eventKey === "logout"){
            keycloak.logout();
            return;
        }

        setActive(eventKey ?? 'customers')
        navigate("/" + eventKey ?? 'customers', {replace: true})
    }

    const [active, setActive] = React.useState('news');
    const [modalOpen, setModalOpen] = useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const iconStyle = {
        height: 25,
        fontSize: 25
    }

    function handleModalClose(){
        setModalOpen(false);
    }

    return (
        <>
            <Stack className="Main-Stack" justifyContent="flex-start">
                <Sidenav className="Main-Nav" expanded={expanded}>
                    <Sidenav.Body>
                        <Hamburger direction="right" rounded={true} onToggle={setExpanded} size={25}/>
                        <Nav style={{marginBottom: 50}} activeKey={active} onSelect={handleNavChange}>
                            <Nav.Item icon={<OffIcon style={iconStyle}/>}
                                      eventKey="logout">Abmelden</Nav.Item>
                            <Nav.Item icon={<PeoplesCustomizeIcon style={iconStyle}/>}
                                      eventKey="customers">Kunden</Nav.Item>
                            <Nav.Item icon={<ProjectIcon style={iconStyle}/>}
                                      eventKey="projects">Projekte</Nav.Item>
                            <Nav.Item icon={<PlusIcon style={iconStyle}/>}
                                      eventKey="projectexpenses">Projektausgaben</Nav.Item>
                            <Nav.Item icon={<TimeIcon style={iconStyle}/>}
                                      eventKey="timetracking">Zeiterfassung</Nav.Item>
                            <Nav.Item icon={<CreditCardPlusIcon style={iconStyle}/>}
                                      eventKey="invoices">Rechnungen</Nav.Item>
                        </Nav>
                    </Sidenav.Body>
                </Sidenav>
                <Grid fluid={true} className="Container Scroller">
                    <Row>
                        <Col md={24}>
                            <Header/>
                        </Col>
                    </Row>
                    <Row className="Main-Content">
                        <Col md={24}>
                            <Outlet/>
                        </Col>
                    </Row>
                </Grid>
            </Stack>

            <Modal open={modalOpen} onClose={handleModalClose}>
                <Modal.Header>
                    <Modal.Title>Anmelden</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoginView setToken={() => console.log("")} useRecaptcha={false} afterLogin={handleModalClose}/>
                </Modal.Body>
            </Modal>

            <ToastContainer
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick={true}
                pauseOnHover={true}
                draggable={true}
            />
        </>
    );
}

export default App;

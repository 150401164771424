import React, {useEffect} from 'react';
import InvoiceTableComponent from "../components/InvoiceTableComponent";
import NewInvoiceModal from "../components/NewInvoiceModal";
import InvoiceData from "../data/InvoiceData";


function Invoices() {
    let [data, setData] = React.useState([])

    function handleGenerateInvoiceDone(){
        initializeData();
    }

    const initializeData = async function () {
        let response = await InvoiceData.getInvoices();
        setData(response);
    }

    useEffect(() => {
        initializeData();
    }, []);


    return (
        <>
            <h1>Rechnungen</h1>
            <NewInvoiceModal onComplete={handleGenerateInvoiceDone}/>
            <InvoiceTableComponent data={data} onRefreshRequest={initializeData}/>
        </>
    );
}

export default Invoices;
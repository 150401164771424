import axios from 'axios';
import DateFormatter from "../helper/DateFormatter";

let TimeTrackingData = {
    getTimeTrackings: async function (searchParams = {projectId: null, customerId: null}) {
        let params = "";

        if(searchParams.projectId)
            params = "?projectId=" + searchParams.projectId;

        if(searchParams.customerId)
            params += (params ? "&" : "?") + "customerId=" + searchParams.customerId;

        if(searchParams.startDate)
            params += (params ? "&" : "?") + "startDate=" + searchParams.startDate;

        if(searchParams.endDate)
            params += (params ? "&" : "?") + "endDate=" + searchParams.endDate;

        let response = await axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/timetracking'+params);
        return await response.data;
    },
    getTimeTracking: async function (timeTrackingId) {
        let response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/timetracking/${timeTrackingId}`);

        return (await response.data)[0];
    },
    addOrUpdateTimeTracking: async function (timeTrackingModel, callback) {
        if (timeTrackingModel.Id > 0) {
            let timeTrackingId = timeTrackingModel.Id;
            delete timeTrackingModel.Id;

            axios.put(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/timetracking',
                {timeTrackingId: timeTrackingId, timeTrackingModel: timeTrackingModel}
            )
                .then((res) => {
                    callback(res.data)
                });
        }
        else {
            delete timeTrackingModel.Id;
            axios.post(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/timetracking', {timeTrackingModel})
                .then((res) => {
                    callback(res.data)
                });
        }
    },
    deleteTimeTracking: async function (id, callback){
        axios.delete(process.env.REACT_APP_API_ENDPOINT_SERVER + '/api/timetracking/' + id)
            .then((res) => {
                callback(res.data)
            });
    },
    getDurationDay: async function (){
        let response = await axios.get(process.env.REACT_APP_API_ENDPOINT_SERVER + "/api/durationday");

        return (await response.data.success ? response.data.data : 0);
    },
    getMonthlyOverview: async function(dateRange) {
        let response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/overview/${dateRange[0].toISOString().slice(0,10)}/${dateRange[1].toISOString().slice(0,10)}`
        );

        return (await response).data.data;
    },
    async getMonthlyTimeReport(dateRange) {
        let response = await axios.get(
            `${process.env.REACT_APP_API_ENDPOINT_SERVER}/api/timereport/${dateRange[0]}/${dateRange[1]}`
        );

        return (await response).data.data;
    }
}

export default TimeTrackingData;
import React, {useEffect} from 'react';
import {Button, Table} from 'rsuite';
import Swal from "sweetalert2";
import ProjectData from "../data/ProjectData";
import {useNavigate} from "react-router-dom";

// Icons
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import VisibleIcon from '@rsuite/icons/Visible';

function Projects() {
    let [tableData, setTableData] = React.useState([]);
    let navigate = useNavigate();
    let [tableBusy, setTableBusy] = React.useState(true);


    const setProjectsData = async () => {
        let response = await ProjectData.getProjectOverview();
        setTableData(response);
        setTableBusy(false);
    }

    useEffect(() => {
        setProjectsData();
    }, []);

    function handleNew() {
        navigate("/project/new", {replace: true});
    }

    function handleEdit(id, event) {
        navigate("/project/edit/" + id, {replace: true});
    }

    function handleView(id, event) {
        navigate("/project/view/" + id, {replace: true});
    }

    function handleDelete(id, event) {
        ProjectData.getProject(id).then((response) => {
            Swal.fire({
                title: "Wirklich löschen?",
                text: `Soll ${response.Title} wirklich gelöscht werden?`,
                icon: "warning",
                confirmButtonText: 'Yes',
                cancelButtonText: "No",
                showCancelButton: true
            }).then((res) => {
                if (res.isConfirmed)
                    ProjectData.deleteProject(id,
                        (response) => {
                            if (response.success)
                                setProjectsData();
                        }
                    );
            })
        });
    }

    return (
        <>
            <h1>Projekte</h1>
            <Button onClick={handleNew} appearance={"primary"}>Neues Projekt</Button>
            <Table data={tableData} loading={tableBusy} autoHeight={true}>
                <Table.Column fixed>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.Cell dataKey="Id"/>
                </Table.Column>
                <Table.Column width={200}>
                    <Table.HeaderCell>Bezeichnung</Table.HeaderCell>
                    <Table.Cell dataKey="Caption"/>
                </Table.Column>
                <Table.Column width={200}>
                    <Table.HeaderCell>Kunde</Table.HeaderCell>
                    <Table.Cell dataKey="CustomerName"/>
                </Table.Column>
                <Table.Column fixed="right" verticalAlign={'middle'}>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.Cell>
                        {rowData => {
                            return (
                                <>
                                    <VisibleIcon className={'Table-Icon'} onClick={(e => handleView(rowData.Id, e))}/>
                                    <EditIcon className={'Table-Icon'} onClick={(e => handleEdit(rowData.Id, e))}/>
                                    <TrashIcon className={'Table-Icon'} onClick={(e => handleDelete(rowData.Id, e))}/>
                                </>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </>
    )
        ;
}

export default Projects;
import React, {useEffect} from "react"
import DateFormatter from "../helper/DateFormatter";
import {Grid, Row, Col, Button, Modal, Table, ButtonToolbar} from "rsuite";

import "../assets/Widgets.css";
import timeTrackingData from "../data/TimeTrackingData";
import dateFormatter from "../helper/DateFormatter";

/**
 *
 * @param props currentDate [def: Today],
 * @returns {JSX.Element} Monthly Overview Widget
 * @constructor
 */
function MonthlyOverview(props) {
    if (props.hasOwnProperty(("eventEmitter"))) {
        if (!props.eventEmitter.listeners("updateMonthlyOverview").length)
            props.eventEmitter.addListener('updateMonthlyOverview', () => updateWidgetData());
    }

    let [currentMonth, setCurrentMonth] = React.useState(
        props.hasOwnProperty("currentDate") ? props.currentDate : new Date()
    );
    let [dateRange, setDateRange] = React.useState(
        DateFormatter.getMonthStartEndDate(props.hasOwnProperty("currentDate") ? props.currentDate : new Date())
    );

    let [timeReportOpen, setTimeReportOpen] = React.useState(false);
    let [dayReportOpen, setDayReportOpen] = React.useState(false);
    let [timeReportData, setTimeReportData] = React.useState([]);
    let [dayReportData, setDayReportData] = React.useState([]);

    let [widgetData, setWidgetData] = React.useState({
        Day: 0,
        Month: 0,
        Week: 0
    });

    let [businessDays, setBusinessDays] = React.useState(0);
    let [businessDaysRemaining, setBusinessDaysRemaining] = React.useState(0);
    let [businessDaysRemainingWeek, setBusinessDaysRemainingWeek] = React.useState(0);
    let [currentWeek, setCurrentWeek] = React.useState([]);
    let [sollDaily, setSollDaily] = React.useState(0);

    async function updateWidgetData() {
        timeTrackingData.getMonthlyOverview(dateRange).then(res => {
                setWidgetData(res);

                let busDays = getBusinessDatesCount(dateRange[0], dateRange[1]);
                setBusinessDays(busDays);
                console.log(`DateRange: ${dateRange[0]} - ${dateRange[1]}`)
                let remBusDays = getBusinessDatesCount(new Date(), dateRange[1]);
                setBusinessDaysRemaining(remBusDays);
                setSollDaily(Math.round((170 - res.Month) / (remBusDays) * 100) / 100)

                let currWeek = getWeekDates(new Date());
                setCurrentWeek(currWeek);

                if (currWeek.length > 0)
                    setBusinessDaysRemainingWeek(getBusinessDatesCount(new Date(), currWeek[1]));
            }
        );
    }

    async function handleShowTimeReport() {
        setTimeReportOpen(true);
        let dateRange = dateFormatter.getMonthStartEndDate(new Date());
        timeTrackingData.getMonthlyTimeReport(dateRange).then((data) => {
            setTimeReportData(data);
        })
    }

    async function handleShowDayReport() {
        setDayReportOpen(true);
        let dateRange = dateFormatter.getMonthStartEndDate(new Date());
        timeTrackingData.getMonthlyTimeReport(dateRange).then((data) => {
            let result = Object.values(data.reduce((a, {Date, Duration}) => {
                a[Date] = (a[Date] || {Date, Duration: 0});
                a[Date].Duration = String(Number(a[Date].Duration) + Number(Duration));
                return a;
            }, {}));

            console.log(result)
            setDayReportData(result);
        })
    }

    useEffect(() => {
        updateWidgetData();
    }, [])

    function handleClose() {
        setTimeReportOpen(false);
        setDayReportOpen(false);
    }

    function download(filename, fileContent) {
        let element = document.createElement('a');
        let universalBOM = "\uFEFF";
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(universalBOM + fileContent));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    function getWeekDates(currentDate) {
        let first = currentDate.getDate() - currentDate.getDay() + 1; // First day is the day of the month - the day of the week
        let last = first + 6; // last day is the first day + 6

        let firstDay = new Date(currentDate.setDate(first));
        let lastDay = new Date(currentDate.setDate(last));

        return [firstDay, lastDay];
    }

    function getBusinessDatesCount(pStartDate, pEndDate) {
        let count = 0;
        let curDate = new Date(pStartDate.getTime());
        let endDate = new Date(pEndDate.getFullYear(), pEndDate.getMonth(), pEndDate.getDate(), 23, 59, 59);

        while (curDate <= endDate) {
            let dayOfWeek = curDate.getDay();
            if (dayOfWeek > 0 && dayOfWeek <= 5) {
                count++;
            }
            curDate.setDate(curDate.getDate() + 1);
        }

        return count;
    }

    function handleDownload() {
        let content = "Date;Project;Description;Ref;Amount\n";

        timeReportData.forEach(value => {
            content += `${value.Date};${value.Caption};${value.Description};${value.Ref};${value.Duration.toString().replace(".", ",")}\n`;
        })

        download("timereport.csv", content);
    }

    function getTimeReportView() {
        return (
            <Modal open={timeReportOpen} overflow={true} onClose={handleClose} size={"lg"}>
                <Modal.Header>
                    <Modal.Title>Time-Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table data={timeReportData} autoHeight={true} minHeight={300}>
                        <Table.Column width={100}>
                            <Table.HeaderCell>Datum</Table.HeaderCell>
                            <Table.Cell dataKey={"Date"}/>
                        </Table.Column>
                        <Table.Column flexGrow={1}>
                            <Table.HeaderCell>Projekt</Table.HeaderCell>
                            <Table.Cell dataKey={"Caption"}/>
                        </Table.Column>
                        <Table.Column flexGrow={2}>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.Cell dataKey={"Description"}/>
                        </Table.Column>
                        <Table.Column width={200}>
                            <Table.HeaderCell>Ref</Table.HeaderCell>
                            <Table.Cell dataKey={"Ref"}/>
                        </Table.Column>
                        <Table.Column width={100} align={"right"} style={{paddingRight: "2em"}}>
                            <Table.HeaderCell>Duration</Table.HeaderCell>
                            <Table.Cell dataKey={"Duration"}/>
                        </Table.Column>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDownload} appearance={"primary"}>
                        Download as CSV
                    </Button>
                    <Button onClick={handleClose} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function getDayReportView() {
        return (
            <Modal open={dayReportOpen} overflow={true} onClose={handleClose} size={"lg"}>
                <Modal.Header>
                    <Modal.Title>Tages-Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table data={dayReportData} autoHeight={true} minHeight={300}>
                        <Table.Column width={100}>
                            <Table.HeaderCell>Datum</Table.HeaderCell>
                            <Table.Cell dataKey={"Date"}/>
                        </Table.Column>
                        <Table.Column width={100} align={"right"} style={{paddingRight: "2em"}}>
                            <Table.HeaderCell>Duration</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData) => {
                                    let style = {};
                                    if (rowData.Duration < sollDaily)
                                        style = {color: "red"};
                                    else style = {color: "green"};
                                    return (<span style={style}>{rowData.Duration}</span>);
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleDownload} appearance={"primary"}>
                        Download as CSV
                    </Button>
                    <Button onClick={handleClose} appearance="primary">
                        Ok
                    </Button>
                    <Button onClick={handleClose} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <div className={"Widget"}>
            <Grid fluid={true}>
                <Row className={"mb-2"}>
                    <Col lg={24}>
                        <span className={"Widget-Title"}>Übersicht {DateFormatter.getNameOfMonth(currentMonth)}</span>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24}>
                        <div className={"Widget-Content"}>
                            <b>Stunden Heute:</b> {widgetData.Day}
                            <br/>
                            <b>Stunden Monat:</b> {widgetData.Month}
                            <br/>
                            <b>Stunden Monat Soll:</b> 170
                            <br/>
                            <b>Verbleibend:</b> {170 - widgetData.Month}
                            <br/>
                            <hr/>
                            <b>Arbeitstage Monat:</b> {businessDays}
                            <br/>
                            <b>Arbeitstage Verbleibend:</b> {businessDaysRemaining}
                            <br/>
                            <b>Soll pro Tag:</b> {sollDaily}
                            <hr/>
                            <p>{dateFormatter.getDateFormatted(currentWeek[0])} - {dateFormatter.getDateFormatted(currentWeek[1])}</p>
                            <b>Arbeitstage Woche Verbleibend:</b> {businessDaysRemainingWeek}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24}>
                        <ButtonToolbar>
                            <Button appearance={"primary"} onClick={handleShowTimeReport}>Time-Report anzeigen</Button>
                            <Button appearance={"primary"} color={"orange"} onClick={handleShowDayReport}>Tages-Report
                                anzeigen</Button>
                        </ButtonToolbar>
                        {getTimeReportView()}
                        {getDayReportView()}
                    </Col>
                </Row>
            </Grid>
        </div>
    );
}

export default MonthlyOverview;
const DateFormatter = {
    getDateFormatted: function (pDate) {
        const t = new Date(pDate);
        let date = ('0' + t.getDate()).slice(-2);
        let month = ('0' + (t.getMonth() + 1)).slice(-2);
        let year = t.getFullYear();

        return `${date}.${month}.${year}`;
    },
    getSQLDateFormatted: function (pDate) {
        const t = new Date(pDate);
        let date = ('0' + t.getDate()).slice(-2);
        let month = ('0' + (t.getMonth() + 1)).slice(-2);
        let year = t.getFullYear();

        return `${year}-${month}-${date}`;
    },
    getTimeFormatted: function (pDate) {
        const t = new Date(pDate);
        let hours = ('0' + t.getHours()).slice(-2);
        let minutes = ('0' + t.getMinutes()).slice(-2);

        return `${hours}:${minutes}`;
    },
    getDuration: function(StartTime, EndTime) {
        const startTime = new Date(StartTime);
        const endTime = new Date(EndTime);
        var startDate = new Date(0, 0, 0, startTime.getHours(), startTime.getMinutes(), 0);
        var endDate = new Date(0, 0, 0, endTime.getHours(), endTime.getMinutes(), 0);
        var diff = endDate.getTime() - startDate.getTime();

        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);

        return `${(hours < 10 ? '0' + hours : hours)}:${(minutes < 10 ? '0' + minutes : minutes)}`;
    },
    getMonthStartEndDate: function(pDate){
        let startDate = new Date(pDate.getFullYear(), pDate.getMonth(), 1);
        let endDate = new Date(pDate.getFullYear(), pDate.getMonth() + 1, 0, 23,59,59);

        return [startDate, endDate];
    },
    /**
     *
     * @param currentMonth {Date}
     */
    getNameOfMonth: function (currentMonth) {
        switch (currentMonth.getMonth()) {
            case 0:
                return "Januar";
            case 1:
                return "Februar";
            case 2:
                return "März";
            case 3:
                return "April";
            case 4:
                return "Mai";
            case 5:
                return "Juni";
            case 6:
                return "Juli";
            case 7:
                return "August";
            case 8:
                return "September";
            case 9:
                return "Oktober";
            case 10:
                return "November";
            case 11:
                return "Dezember";
            default:
                return "";
        }
    }
}

export default DateFormatter;
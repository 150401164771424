import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Button, ButtonToolbar, Form} from "rsuite";
import LoginController from "./Login.controller";

export default function LoginView({useRecaptcha, afterLogin}) {
    let [authenticated, setAuthenticated] = useState(false);
    let [formData, setFormData] = useState({
        username: "",
        password: ""
    });

    function handleLogin() {
        LoginController.handleLogin(formData);
        afterLogin();
    }

    return (
        <div className={"Login-Form"}>
            <Form fluid={true} onChange={setFormData}>
                <Form.Group controlId={"username"}>
                    <Form.ControlLabel>Username / E-Mail</Form.ControlLabel>
                    <Form.Control name={"username"}/>
                </Form.Group>
                <Form.Group controlId={"password"}>
                    <Form.ControlLabel>Password</Form.ControlLabel>
                    <Form.Control name={"password"} type={"password"} autoComplete={"off"}/>
                </Form.Group>
                <ButtonToolbar style={{textAlign: "right"}}>
                    <Button appearance={"primary"} color={"green"} onClick={handleLogin}>Anmelden</Button>
                    <Button appearance={"primary"} color={"red"} onClick={afterLogin}>Abbrechen</Button>
                </ButtonToolbar>
            </Form>
        </div>
    );
}

LoginView.propTypes = {
    afterLogin: PropTypes.func.isRequired,
    useRecaptcha: PropTypes.bool.isRequired
}

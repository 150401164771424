import React from 'react';
import ReactDOM from 'react-dom';
import './assets/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import axios from "axios";
import keycloak from "./keycloak";
import {ReactKeycloakProvider} from "@react-keycloak/web";

// Pages
import Invoices from "./pages/Invoices";
import Invoice from "./pages/Invoice";
import TimeTracking from "./pages/TimeTracking";
import Customers from "./pages/Customers";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import InvoiceConfig from "./pages/InvoiceConfig";
import Expenses from "./pages/Expenses";


const initOption = {
    onLoad: "login-required",
    checkLoginIframe: false
};

axios.interceptors.request.use(async (config) => {
    // Token exists, refresh it
    await keycloak.updateToken(5);
    // Modify Axios config
    config.headers = {
        ...config.headers,
        Authorization: `Bearer ${keycloak.token}`,
    };
    return config;
}, (error) => {
    console.log('Failed to refresh token with error ' + error.message);
});


ReactDOM.render(
    <ReactKeycloakProvider initOptions={initOption} authClient={keycloak}>
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<App/>}>
                        <Route path="invoices" element={<Invoices/>}/>
                        <Route path="invoice">
                            <Route path=":type" element={<Invoice/>}>
                                <Route path=":invoiceId" element={<Invoice/>}/>
                            </Route>
                            <Route path={"config"} element={<InvoiceConfig/>}>
                                <Route path={":configId"} element={<InvoiceConfig/>}/>
                            </Route>
                        </Route>
                        <Route path="timetracking" element={<TimeTracking/>}/>
                        <Route path="customers" element={<Customers/>}/>
                        <Route path="projects" element={<Projects/>}/>
                        <Route path="project" element={<Project/>}>
                            <Route path=":type" element={<Project/>}>
                                <Route path=":id" element={<Project/>}/>
                            </Route>
                        </Route>
                        <Route path="projectexpenses" element={<Expenses/>}/>
                        <Route
                            path="*"
                            element={
                                <main style={{padding: "1rem"}}>
                                    <p>There's nothing here!</p>
                                </main>
                            }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    </ReactKeycloakProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

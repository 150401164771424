import React from 'react';
import {Params, useParams} from 'react-router-dom';
import '../data/InvoiceData';
import InvoiceData from "../data/InvoiceData";
import {Button, ButtonToolbar, DatePicker, Form, Grid, Row, Col} from 'rsuite';

let invoiceData = {Customer: '', InvoiceNo: '', InvoiceDate: new Date()};

function Invoice(){
    let params = useParams();
    return generateView(params);
}

function generateView(params){
    invoiceData = InvoiceData.getInvoice(parseInt(params.invoiceId ?? '0'))
    console.log(invoiceData);
    return (
        <>
            <h1>Rechnung</h1>
            <Form fluid plaintext={true} formValue={invoiceData}>
                <Grid fluid>
                    <Row className={"mb-2"}>
                        <Col md={24}>
                            <Form.Group controlId="Customer">
                                <Form.ControlLabel>Kunde</Form.ControlLabel>
                                <Form.Control className={"text-bold"} name="Customer"/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={24}>
                            <Form.Group controlId="InvoiceDate">
                                <Form.ControlLabel>Rechnungsdatum</Form.ControlLabel>
                                <Form.Control  name="InvoiceDate" accepter={DatePicker} />
                            </Form.Group>
                        </Col>
                        <Col md={12} xs={24}>
                            <Form.Group controlId="InvoiceNo">
                                <Form.ControlLabel>Rechnungsnummer</Form.ControlLabel>
                                <Form.Control name="InvoiceNo"/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </Form>
        </>
    );
}

export default Invoice;
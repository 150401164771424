import React, {useEffect} from 'react';
import {Button, ButtonToolbar, DatePicker, Modal, Table} from 'rsuite';
import InvoiceData from "../data/InvoiceData";
import Swal from 'sweetalert2';
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack';
import {toast} from "react-toastify";

function InvoiceTableComponent(props) {
    let [data, setData] = React.useState([]);
    let [invoicePreviewOpen, setInvoicePreviewOpen] = React.useState(false);
    let [invoicePreviewTitle, setInvoicePreviewTitle] = React.useState("");
    let [numPages, setNumPages] = React.useState(0);
    let [pageNumber, setPageNumber] = React.useState(1);
    let [pdfFile, setPdfFile] = React.useState(null);
    let [modalStyle, setModalStyle] = React.useState({display: "none"});

    function handleInvoicePreviewClose() {
        setInvoicePreviewOpen(false);
        setNumPages(1);
        setPageNumber(1);
        setModalStyle({display: "none"});
    }

    async function handleInvoicePreviewOpen(invoiceData) {
        setInvoicePreviewTitle(`Rechnung ${invoiceData.InvoiceNo}`);
        setInvoicePreviewOpen(true);

        InvoiceData.getInvoice(invoiceData.Id).then((res) => {
            setPdfFile(res);
        });
    }

    async function handleInvoiceDownload(invoiceData) {
        InvoiceData.getInvoice(invoiceData.Id).then((res) => {
            let downloadLink = document.createElement("a");
            downloadLink.href = res;
            downloadLink.download = `Rechnung_${invoiceData.InvoiceNo}.pdf`;
            downloadLink.click();
        });
    }

    useEffect(() => {
        setData(props.data);
    }, [props])

    function handleDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setModalStyle({display: numPages > 1 ? "block" : "none"});
    }

    function handleGoToPrevPage(){
        if(pageNumber === 1)
            return;

        setPageNumber(pageNumber - 1);
    }
    function handleGoToNextPage(){
        if(pageNumber === numPages)
            return;

        setPageNumber(pageNumber + 1);
    }

    function fireRefreshEvent(){
        if(typeof (props.onRefreshRequest) == "function"){
            props.onRefreshRequest();
        }
    }

    function handleDeleteInvoice(rowData){
        InvoiceData.deleteInvoice(rowData, (res) =>{
            if(res.success)
            {
                toast("Rechnung erfolgreich gelöscht", {type: "success"});
                fireRefreshEvent();
            }
            else toast("Rechnung konnte nicht gelöscht werden", {type: "error"});
        });
    }

    return (
        <>
            <Table data={data} autoHeight={true}>
                <Table.Column width={200}>
                    <Table.HeaderCell>Invoice-No</Table.HeaderCell>
                    <Table.Cell dataKey="InvoiceNo"/>
                </Table.Column>
                <Table.Column width={200}>
                    <Table.HeaderCell>Customer</Table.HeaderCell>
                    <Table.Cell dataKey="Customer"/>
                </Table.Column>
                <Table.Column>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.Cell dataKey="Amount"/>
                </Table.Column>
                <Table.Column width={200}>
                    <Table.HeaderCell>InvoiceDate</Table.HeaderCell>
                    <Table.Cell>
                        {rowData => {
                            return (<DatePicker plaintext value={new Date(rowData.InvoiceDate)} format={"dd.MM.yyyy"}/>)
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column fixed="right" width={250}>
                    <Table.HeaderCell>Action</Table.HeaderCell>
                    <Table.Cell>
                        {rowData => {
                            function handleAction() {
                                handleInvoicePreviewOpen(rowData);
                            }

                            function handleDownload() {
                                handleInvoiceDownload(rowData);
                            }

                            function handleDelete() {
                                handleDeleteInvoice(rowData);
                            }

                            return (
                                <>
                                    <a style={{cursor: 'pointer'}} onClick={handleAction}> Show</a>
                                    <a style={{cursor: 'pointer'}} onClick={handleDownload}> Download</a>
                                    <a style={{cursor: 'pointer'}} onClick={handleDelete}> Delete</a>
                                </>
                            );
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
            <Modal open={invoicePreviewOpen} onClose={handleInvoicePreviewClose} size={"lg"}>
                <Modal.Header>
                    <Modal.Title>{invoicePreviewTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Document file={pdfFile} className={"Pdf-Center"} onLoadSuccess={handleDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber}/>
                    </Document>
                </Modal.Body>
                <Modal.Footer className={"mt-2"}>
                    <ButtonToolbar className={"text-center"} style={modalStyle}>
                        <Button onClick={handleGoToPrevPage}>Zurück</Button>
                        <Button onClick={handleGoToNextPage}>Weiter</Button>
                    </ButtonToolbar>
                    Seite {pageNumber} von {numPages}

                </Modal.Footer>
            </Modal>
        </>
    );
}

export default InvoiceTableComponent;
import React, {useEffect, useState} from 'react';
import {Button, Table, DateRangePicker} from "rsuite";
import Swal from 'sweetalert2';

// Icons
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import CheckRoundIcon from '@rsuite/icons/CheckRound';

import DateFormatter from "../helper/DateFormatter";

// Data
import TimeTrackingData from "../data/TimeTrackingData";
import TimeTrackingModal from "./TimeTrackingModal";

function TimeTrackingOverview(props) {
    if(props.hasOwnProperty(("eventEmitter"))) {
        if(!props.eventEmitter.listeners("updateTimeTracking").length)
            props.eventEmitter.addListener('updateTimeTracking', () => {
                updateTimeOverview();
            });
    }

    let [timeTrackingOverview, setTimeTrackingOverview] = useState([]);
    let [timeTrackingEditId, setTimeTrackingEditId] = useState(null);
    let [tableBusy, setTableBusy] = useState(true);
    let [modalOpen, setModalOpen] = useState(false);
    let [dateRange, setDateRange] = useState([new Date("1970-01-01"), new Date()])
    let [projectId, setProjectId] = useState(props["projectId"]);
    let [customerId, setCustomerId] = useState(props["customerId"]);
    let [autoHeight, setAutoHeight] = useState(props["autoHeight"] ? props["autoHeight"] : false);
    let [height, setHeight] = useState(props["height"] ? props["height"] : 1000);


    async function updateTimeOverview(startDate, endDate) {
        setTableBusy(true);

        setTimeTrackingOverview(await TimeTrackingData.getTimeTrackings({
                projectId: projectId,
                customerId: customerId,
                startDate: startDate ? DateFormatter.getSQLDateFormatted(startDate) : null,
                endDate: endDate ? DateFormatter.getSQLDateFormatted(endDate) : null
            })
        );
        setTableBusy(false);
    }

    function handleEdit(Id, e) {
        setTimeTrackingEditId(Id);
        setModalOpen(true);
    }

    function handleDelete(Id, e) {
        Swal.fire({
            title: "Wirklich löschen?",
            text: "Soll die Zeiterfassung wirklich gelöscht werden?",
            icon: "warning",
            confirmButtonText: 'Löschen',
            cancelButtonText: "Abbrechen",
            showCancelButton: true
        }).then((res) => {
            if (res.isConfirmed)
                TimeTrackingData.deleteTimeTracking(Id,
                    (response) => {
                        if (response.success)
                            updateTimeOverview();
                    }
                );
        })
    }

    useEffect(() => {
        updateTimeOverview();
    }, []);

    function handleNewTimeTracking(ttModel, callback) {
        TimeTrackingData.addOrUpdateTimeTracking(ttModel, (res) => {
            if (res.success) {
                callback(true);
                updateTimeOverview();
                setModalOpen(false);
            }
        });
    }

    function handleTimeRangeChanged(val) {
        if(val)
            updateTimeOverview(val[0], val[1]);
        else updateTimeOverview();

        setDateRange(val);
    }

    return (
        <>
            <TimeTrackingModal timeTrackingId={timeTrackingEditId} open={modalOpen} onClose={() => setModalOpen(false)}
                               onSave={handleNewTimeTracking}/>
            <div className={"mt-2 mb-2"}>
                <label className={"d-block"}>Zeitraum</label>
                <DateRangePicker format={"dd.MM.yyyy"} character={" — "} placement={"auto"}
                                 onChange={handleTimeRangeChanged} value={dateRange}/>
            </div>
            <Table data={timeTrackingOverview}
                   cellBordered={true}
                   loading={tableBusy}
                   autoHeight={autoHeight}
                   height={height}>
                <Table.Column>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.Cell align={"center"}>
                        {(rowData => {
                            if (rowData.Locked)
                                return (<CheckRoundIcon/>);
                            else return (<>Offen</>);
                        })}
                    </Table.Cell>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Projekt</Table.HeaderCell>
                    <Table.Cell dataKey={"ProjectName"}/>
                </Table.Column>
                <Table.Column flexGrow={1}>
                    <Table.HeaderCell>Tätigkeit</Table.HeaderCell>
                    <Table.Cell dataKey={"Description"}/>
                </Table.Column>
                <Table.Column width={150}>
                    <Table.HeaderCell>Kunde</Table.HeaderCell>
                    <Table.Cell dataKey={"CustomerName"}/>
                </Table.Column>
                <Table.Column width={100}>
                    <Table.HeaderCell>Datum</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            return (<>{DateFormatter.getDateFormatted(rowData.StartDate)}</>);
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column>
                    <Table.HeaderCell>Start</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            return (<>{DateFormatter.getTimeFormatted(rowData.StartTime)}</>);
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column>
                    <Table.HeaderCell>Ende</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            return (<>{DateFormatter.getTimeFormatted(rowData.EndTime)}</>);
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column>
                    <Table.HeaderCell>Dauer</Table.HeaderCell>
                    <Table.Cell>
                        {(rowData) => {
                            return (<>{DateFormatter.getDuration(rowData.StartTime, rowData.EndTime)}</>);
                        }}
                    </Table.Cell>
                </Table.Column>
                <Table.Column>
                    <Table.HeaderCell>Aktionen</Table.HeaderCell>
                    <Table.Cell>
                        {rowData => {
                            if (!rowData.Locked) {
                                return (
                                    <>
                                        <EditIcon className={'Table-Icon'}
                                                  onClick={(e => handleEdit(rowData.Id, e))}/>
                                        <TrashIcon className={'Table-Icon'}
                                                   onClick={(e => handleDelete(rowData.Id, e))}/>
                                    </>
                                );
                            } else return (<></>);
                        }}
                    </Table.Cell>
                </Table.Column>
            </Table>
        </>
    );
}

export default TimeTrackingOverview;
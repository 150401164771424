import React from 'react';
import '../assets/App.css';


function Header(){
    return (
        <div className="header">
        </div>

    );
}

export default Header;